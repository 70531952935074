body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f6f8fa;
}

img {
  width: 30vw; /* 30% of the viewport width */
  max-width: 100%; /* Ensure it doesn't exceed its actual size */
  margin-bottom: 20px;
}

.divider {
  width: 80%;
  border-bottom: 1px solid #e0e0e0;
  margin: 10px 0;
}

.text-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: inherit; /* Larger font size -- setting dynamically in the component though  -- was 1.5em*/
  font-weight: bold; /* Bold text */
}

footer {
  margin-top: 50px;
  font-size: 0.9em;
  color: #555;
}
